import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { FilterDropdownBase, NumericRange, PriceIcon } from '@jsmdg/yoshi';
import { type Filter as FilterType } from '../../../../../../shared/types/search';
import { PriceFilterDefault } from '../../../../../enums/priceFilterDefault';
import { usePriceFilterPLP as usePriceFilter } from '../../../../../hooks';
import { type SearchReducerActionType } from '../../../../../reducers/searchReducer';
import { type Nullable } from '../../../../../types';
import { FloatingTogglePriceMessage } from '../../../../PriceMessage';
import { InputPriceControls } from './InputPriceControls/InputPriceControls';
import styles from '../common.module.scss';

const PRICE_BOUNDARY_MIN = PriceFilterDefault.Min;
const PRICE_BOUNDARY_MAX = PriceFilterDefault.Max;

const messages = defineMessages({
    label: {
        defaultMessage: 'Wie viel?',
    },
    dropdownLabel: {
        defaultMessage: 'Alle Preise',
    },
    submitLabel: {
        defaultMessage: 'Fertig',
    },
    resetLabel: {
        defaultMessage: 'Zurücksetzen',
    },
});

type PriceFilterProps = {
    readonly currencyCode: string;
    readonly onSubmit: (
        label: SearchReducerActionType,
        value: { min: Nullable<number>; max: Nullable<number> } | Nullable<number>,
    ) => void;
    readonly values: Array<Nullable<number>>;
    readonly filter: FilterType;
};

const PriceFilter = ({ currencyCode, filter, onSubmit, values }: PriceFilterProps): JSX.Element => {
    const {
        handleOnClickOutside,
        handleReset,
        inputsValues,
        onInputUpdate,
        onPriceChange,
        onRangeChange,
        priceData,
        rangeValues,
    } = usePriceFilter({
        currencyCode,
        onSubmit,
        options: { min: PRICE_BOUNDARY_MIN, max: PRICE_BOUNDARY_MAX },
        values,
        filter,
        isHeroStage: true,
    });
    const intl = useIntl();
    const hasPriceSelected = !priceData.isDefaultMin || !priceData.isDefaultMax;

    const getPriceLabel = (): JSX.Element | string => {
        if (hasPriceSelected) {
            return <FloatingTogglePriceMessage priceData={priceData} />;
        }

        return intl.formatMessage(messages.dropdownLabel);
    };

    return (
        <div
            className={classNames(styles.wrapper, 'd-flex flex-xs-column w-100 p-0', {
                [styles.isActive]: hasPriceSelected,
            })}
        >
            <div className="d-flex align-items-start">
                <PriceIcon className={classNames(styles.icon, 'ml-1x mr-0-5x')} />
                <p className={classNames(styles.label, 'd-none d-xs-block fw-semibold')}>
                    {intl.formatMessage(messages.label)}
                </p>
            </div>

            <FilterDropdownBase
                className={styles.filterDropdown}
                label={getPriceLabel()}
                resetLabel={intl.formatMessage(messages.resetLabel)}
                submitLabel={intl.formatMessage(messages.submitLabel)}
                onSubmit={() => {
                    onPriceChange(rangeValues);
                }}
                onReset={handleReset}
                onClickOutside={handleOnClickOutside}
                hasChanged
                showNumSelected={false}
                numSelected={1}
                filterDropdownButtonClasses={classNames(
                    styles.filterButton,
                    'w-100 py-0 pl-0-5x pl-xs-2x justify-content-between',
                    { [styles.placeholder]: !hasPriceSelected },
                )}
                filterDropdownOverlayClasses={classNames(
                    styles.filterDropdownOverlay,
                    'mt-2x mt-xs-1-5x',
                )}
            >
                <div className="px-3x">
                    <InputPriceControls inputsValues={inputsValues} onInputUpdate={onInputUpdate} />

                    <NumericRange
                        values={rangeValues}
                        onChange={((val: number[]) => onRangeChange(val, true)) as () => void}
                        options={[PRICE_BOUNDARY_MIN, PRICE_BOUNDARY_MAX]}
                        step={10}
                        pushable={10}
                    />
                </div>
            </FilterDropdownBase>
        </div>
    );
};

export { PriceFilter };
